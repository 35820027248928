import React from 'react';
import Container from 'common/source/components/UI/Container';

import OsramCategoryWrapper from './osramcategory.style';

const OsramCategoryBody = ({ node }) => {
  const { html } = node;
  // console.log({ html });
  return (
    <Container>
      <OsramCategoryWrapper>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </OsramCategoryWrapper>
    </Container>
  );
};

export default OsramCategoryBody;
